var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9ffe597f158f56a0291fd7b12e665c51bb5b0f18"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const ignoreErrorCodes = ['err_frozen', 'err_auth']

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://0eda825baacb4998aa3b1be1afd0a4ea@sentry.patrianna.com/5',
  environment: process.env.SENTRY_ENV || 'production',

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  denyUrls: [
    /paywithmybank\.com/,
    /localhost:3000/,
    /stats\.g\.doubleclick\.net/,
    /analytics\.com/,
    /tiktok\.com/,
    /chatcreate\.com/,
    /recaptcha__en/i,
  ],

  beforeSend(event, hint) {
    const error = hint.originalException

    if (ignoreErrorCodes.includes(error?.status?.errorCode)) {
      return null
    }

    return event
  },

  ignoreErrors: [
    // Facebook borked
    'fb_xd_fragment',

    'The operation is insecure.',
    'Failed to fetch',
    'Load failed',
    "Cannot read properties of null (reading 'Error')",
    'attempted to hard navigate to the same URL',
    'Hydration failed because the initial UI does not match what was rendered on the server',
    'Non-Error promise rejection captured with value: Timeout', // it fails due to recaptcha and doesn't affect real users
    'SecurityError',
    "Failed to execute 'measure' on 'Performance'",
    "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node", // it fails due to recaptcha and doesn't affect real users
  ],
  tracesSampleRate: 0.001,
  enabled: process.env.NODE_ENV !== 'development',
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
